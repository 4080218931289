import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import {userType, paymentStatus, paymentType } from '../components/global';
import { useSelector, useDispatch } from "react-redux";
import { updateSubscription } from "../redux/actions/userAction";
import Navbar from './Navbar';
import { ToastContainer, toast } from 'react-toastify';
import { clearLoginData } from "../redux/actions/userAction";
import { signOut } from 'next-auth/client';

export default function subscribe() {

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [selectSubcription, setSelectSubcription] = useState([]);
    const [selectSubcriptionData, setSelectSubcriptionData] = useState([]);
    const [currentProduct, setCurrentProduct] = useState("NONE");
    const [activeSubscriptionData, setActiveSubscriptionData] = useState([]);
    const [cardEnteredError, setCardEnteredError] = useState("");
    const [selectSubscriptionError, setSelectSubscriptionError] = useState("");
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [subsPrices, setsubsPrices] = useState([])

    const dispatch = useDispatch();
    const router = useRouter();
    const stripe = useStripe();
    const elements = useElements();

    const logindata = useSelector((state) => state.user);
    var islogged = logindata.islogged
    let userId, user_Type, token, instituteId, firstName, lastName, customerStripeId = null;
    if (islogged) {
        userId = logindata?.userdata._id
        firstName = logindata?.userdata.firstName
        lastName = logindata?.userdata.lastName
        user_Type = logindata?.userdata.userType
        token = logindata?.token
        instituteId = logindata?.userdata.institute?._id;
        customerStripeId = logindata?.userdata?.customerStripeId;
    }
    if (!islogged || user_Type == userType.admin) {
        router.replace("/");
        return null;
    }

    let stripeLoaded = false;
    if (!stripe && !elements) stripeLoaded = false;
    else stripeLoaded = true;

    // FETCH ACTIVE SUBSCRIPTION AS PER CUSTOMER 
    // AND 
    // FETCH PRODUCTS PRICES
    useEffect(() => {
        async function fetchsubscriptionperuser() {
            const dbResponse = await fetch(`/api/subscription?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            let dbResponse1 = await dbResponse.json();
            if (dbResponse1.success == false) {
                setIsDataFetched(true);
            }else{
                if (dbResponse1.payment.status != paymentStatus.active) {
                    setIsDataFetched(true);
                } else {
                    setIsDataFetched(true);
                    setActiveSubscriptionData(dbResponse1.payment)
                    setCurrentProduct("Basic")
                }
            }
        }
        async function fetchsubscriptionprice() {
            const response = await fetch(`/api/listSubscriptionPrice`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
            });
            let subsPrices = await response.json();
            setsubsPrices(subsPrices)
        }
        fetchsubscriptionprice()
        fetchsubscriptionperuser()
    }, [])

    // Loader Script
    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName("div")[0].appendChild(tag);
    }
    // Styling For Stripe CardElement
    const inputStyle = {
        iconColor: '#c4f0ff',
        color: '#666666',
        backgroundColor: "#ffffff",
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        fontSize: '1.1em',
        fontWeight: 400,
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
            color: '#666666',
        },
        '::placeholder': {
            color: '#666666',
        },
    }
    // SELECT BOX LOGIC TO CHOOSE SUBSCRIPTION
    const subsPricesOption = subsPrices.map(price => {
        return (
            <option key={price.id} value={JSON.stringify(price)} >${price.unit_amount/100}/{price.recurring.interval}</option>
        )
    });
    const handleSelectSubscription = async (event) => {
        const value = event.target.value;
        if (value == ""){
            setSelectSubcriptionData([])
            setSelectSubcription([])
            setSelectSubscriptionError("Please Select Subscription!");
        }else{
            setSelectSubcriptionData(JSON.parse(value))
            setSelectSubcription(value)
            setSelectSubscriptionError("");
        }
    }

    // HANDLE SUBMIT SUBSCRIPTION
    const handleSubmit = async (e) => {
        e.preventDefault();
        const cardElement = elements.getElement(CardElement);
        if (selectSubcription == "" || selectSubcription == null || selectSubcription == undefined) {
            setSelectSubscriptionError("Please Select Subscription!");
        } 
        else setSelectSubscriptionError("")

        var ltd = setInterval(function () {
            loadScript('https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js');
        }, 1000);
        let result = await stripe.createToken(cardElement);
        if (result.error) {
            setCardEnteredError(result.error.message);
            clearInterval(ltd);
        }else{
            const res = await fetch('/api/customer-payment-method', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({customerId: customerStripeId, card_tokenid: result.token.id})
            }).then(r => r.json());
            if (res.success == "false") {
                clearInterval(ltd);
                toast.error(res.error, { position: "top-center" })
            }else{
                const res = await fetch('/api/create-subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        priceId: selectSubcriptionData.id,
                        customerId: customerStripeId
                    }),
                }).then(r => r.json());
                if (res.success == "false") {
                    clearInterval(ltd);
                    toast.error(res.error, { position: "top-center" })
                }else{
                    const resdb = await fetch(`/api/subscription`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token,
                        },
                        body: JSON.stringify({
                            userId: userId,
                            instituteId: instituteId,
                            paymentId : res.subscriptionId,
                            paymentType : paymentType.subscription,
                            paymentAmount: parseFloat(selectSubcriptionData.unit_amount/100),
                            interval:selectSubcriptionData.recurring.interval,
                            isSubscribed: true,
                            status: paymentStatus.active,
                            createdBy: userId
                        })
                    });
                    let res2 = await resdb.json();
                    if (res2) {
                        toast.success('Your have successfully subscribed', { position: "top-center" })
                        clearInterval(ltd);
                        setTimeout(() => {
                            dispatch(updateSubscription({ xyz: true }));
                        }, 2000);
                    }
                    router.push("/")
                }
            }
        }

    }

    // Cancel Subscription
    const handleCancelSubscription = async () => {
        var ltd = setInterval(function () {
            loadScript('https://cdn.jsdelivr.net/npm/pace-js@latest/pace.min.js');
        }, 1000);
        const res = await fetch(`/api/cancelSubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify({
                customerId: customerStripeId,
                subscriptionId: activeSubscriptionData.paymentId,
            })
        });
        const res2 = await res.json();
        if (res2.subscription.status == 'canceled') {
            const response = await fetch(`/api/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    userId: userId,
                    instituteId: instituteId,
                    paymentId: activeSubscriptionData.paymentId,
                    paymentType : paymentStatus.canceled,
                    paymentAmount: activeSubscriptionData.paymentAmount,
                    interval:activeSubscriptionData.interval,
                    isSubscribed: false,
                    status: paymentStatus.canceled,
                    createdBy: userId
                })
            });
            let response2 = await response.json();
            if (response2) {
                dispatch(updateSubscription({ xyz: false }));
                setCurrentProduct("NONE")
                clearInterval(ltd);
                router.push("/")
            }
        }
    }

    return (
        <>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png"></link>
                <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png"></link>
                <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png"></link>
                <link rel="icon" href="/images/favicon/favicon.ico" type="image/x-icon" />
                <title>Subscription</title>
            </Head>
            <>
                <Navbar/>
                {logindata?.isSubscribed == false && user_Type == userType.teacher ? <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "100vh" }}>
                    <div>
                        <h1>Welcome To Yam</h1>
                        <p className="lead" style={{ margin: "1rem 0 3rem 0" }}>Sorry but you can not access web panel as your institute is not subscribed. Please contact your institute admin.</p>
                    </div>
                </div> : null}

                {user_Type == userType.instituteAdmin ? 
                <div>
                {stripeLoaded && isDataFetched ?
                    <>
                        {currentProduct == "NONE" ?
                            <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                <h1>Welcome To Yam</h1>
                                <p className="lead">Kindly Subscribe To Onboard.</p>
                            </div>
                        : null}

                        <section className={currentProduct == "NONE" ? "pt-2" : "pt-5 mt-3"}>
                            <div className="container-fluid">
                                <div className="row d-flex justify-content-center text-center mb-0 mb-sm-0 mb-md-2 mb-lg-5" style={{ width: "100%" }}>
                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                        <div className="card mb-5 mb-lg-0 subscard">
                                            <div className="card-header d-flex justify-content-center p-4">
                                                <h3 className="text-center">Current Subscription - </h3>
                                                <h3 className="text-center ml-2">{currentProduct != "NONE" ? <span style={{ color: "#34A9DE" }}>${activeSubscriptionData.paymentAmount}/{activeSubscriptionData.interval}</span> : "NONE"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {currentProduct == "NONE" ?
                                    <>
                                        <div className="row d-flex justify-content-center mb-0 mb-sm-0 mb-md-2 mb-lg-2" style={{ width: "100%" }}>
                                            <div className="col-sm-12 col-md-12 col-lg-6 text-left">
                                                <label className="form-label mb-0">Choose Subscription <span className="text-danger">*</span></label>
                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center mb-0 mb-sm-3 mb-md-3 mb-lg-3" style={{ width: "100%" }}>
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="subscard1">
                                                    <select className="form-control" name="selectSubcriptions" tabIndex="-98" value={selectSubcription} onChange={handleSelectSubscription}>
                                                        <option key={0} value={""}>Select Subscription Plan</option>
                                                        {subsPricesOption}
                                                    </select>
                                                </div>
                                                {selectSubcription == "" || selectSubcription == null || selectSubcription == undefined ? <small className='text-danger'>{selectSubscriptionError}</small> : null}
                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center text-center mb-0 mb-sm-0 mb-md-2 mb-lg-5" style={{ width: "100%" }}>
                                            <div className="col-sm-12 col-md-12 col-lg-6 text-left">
                                                <label>Credit Card Details</label> <span className="text-danger">*</span>
                                                <CardElement options={{ hidePostalCode: true, style: { base: inputStyle}}} onChange={()=> setCardEnteredError("")}/>
                                                <small className='text-danger'>{cardEnteredError}</small>
                                            </div>
                                        </div>

                                        <div className="row d-flex justify-content-center text-center mt-3 mb-0 mb-sm-0 mb-md-2 mb-lg-5" style={{ width: "100%" }}>
                                            <div className="col-sm-6 col-md-6 col-lg-2 mb-1">
                                                <button className="btn btn-primary btn-lg w-100" onClick={e => handleSubmit(e)}>
                                                    Pay
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    : <section className="py-1">
                                        <div className="container-fluid">
                                            <div className="row d-flex justify-content-center mb-0 mb-sm-0 mb-md-2 mb-lg-2" style={{ width: "100%" }}>
                                                <div className="col-sm-12 col-md-12 col-lg-6">
                                                    <ul class="list-display list-checkmarks">
                                                        <li>Upload multiple courses</li>
                                                        <li>AI Generated Questions</li>
                                                    </ul>
                                                    <div className="text-center mb-1">
                                                        {currentProduct != "NONE" ? <button type="button" className="btn btn-danger" onClick={handleCancelSubscription}><strong>Cancel Subscription</strong></button> : null}

                                                    </div>
                                                    <div className="text-left">
                                                        <small>
                                                            - Your subscription will auto-renew and you will be charged the amount each month.
                                                        </small><br />
                                                        <small>
                                                            - You can cancel subscription at anytime.
                                                        </small><br />
                                                        <small>
                                                            - if you cancel, you would pay for only the active subscription time period.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }

                            </div>
                        </section>
                    </>
                    : null}
                    </div> : null}
            </>
            
            <ToastContainer autoClose={true} autoClose={5000} />
        </>
    );
}


